<template>
    <v-card class="custom-border d-flex flex-column justify-space-between" max-width="250" elevation="2">
        <section class="cursor-pointer" @click="$router.push({name: 'User Course Details', params: {id: data.uuid}})">
            <v-img
                v-if="data.image"
                max-width="250"
                height="132"
                :src="data.image.url"
                v-on:error="require('@/assets/images/course-placeholder-1.png')"
                class="rounded-t"/>
            <v-sheet 
                v-if="!data.image"
                width="250"
                height="132"
                :color="!data.image ? (data.course_card ? data.course_card.color : selectedColor)  : 'primary'" 
                class="hover border-top" >
                <v-menu offset-y v-if="!data.image ">
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        color="white"
                        class="float-right"
                        v-bind="attrs"
                        v-on="on"
                        :loading="loading"
                    >
                        <v-icon >
                        mdi-dots-vertical
                        </v-icon>
                    </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                        <v-menu right offset-x :close-on-content-click="menu">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn left text v-bind="attrs" v-on="on" class="text-capitalize poppins f12">
                                Change Color
                                <v-icon right>mdi-chevron-right</v-icon>
                                </v-btn>
                            </template>
                            <v-sheet width="250">
                                <v-color-picker
                                hide-canvas
                                hide-inputs
                                hide-sliders
                                hide-mode-switch
                                class="pa-2"
                                mode="rgba"
                                show-swatches
                                swatches-max-height="150"
                                v-model="selectedColor"
                                @update:color="changeColor"
                                />
                            </v-sheet>
                            </v-menu>
                        </v-list-item>
                        <v-list-item>
                        <v-btn text class="text-capitalize poppins f12" @click="changeFavorite">
                            Mark as {{ favorite ? 'Unfavorite' : 'Favorite' }}
                            <v-icon color="yellow" right v-if="favorite">
                                {{ favorite ? 'mdi-star' :  'mdi-star-outline' }}
                            </v-icon>
                        </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-sheet>
            <v-card-subtitle class="poppins fw600 pb-0">
                {{ data.title.length > 35 ? `${data.title.slice(0, 31)}...` : data.title}}
            </v-card-subtitle>
            <v-card-subtitle class="poppins fw600 py-0 secondary-2--text f11" v-if="tenant === 'corporate'">
                {{ data.training_hours }} {{ data.training_hours > 1 ? 'hours' : 'hour' }} • {{ data.modules_count ? data.modules_count : 0 }} {{ data.modules_count > 1 ? 'modules' : 'module'}} 
            </v-card-subtitle>
        </section>
        <section class="cursor-pointer" @click="$router.push({name: 'User Course Details', params: {id: data.uuid}})">
            <v-container class="mx-1">
                <v-progress-linear 
                    height="8"
                    :value="progress"
                    rounded
                    color="success"
                    background-color="grey lighten-3"
                ></v-progress-linear>
                <b class="poppins secondary-1--text fw600 f13 my-2">
                    {{ progress }}% Complete
                </b>
            </v-container>
            <v-btn block tile flat class="py-5 poppins f12 rounded-b text-capitalize" color="primary" @click="$router.push({name: 'User Course Details', params: {id: data.uuid}})">
                {{ progress === 0 ? 'Start Course' : progress === 100 ? 'Completed' : 'Continue Course'}}
            </v-btn>
        </section>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
    props: ['data', 'progress'],
    data:() => ({
        menu: false,
        selectedColor: '#1565C0FF',
        asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
        loading: false
    }),
    methods: {
        ...mapActions('usr', ['updateCourseColor', 'updateCourseFavorite', 'updateCourseFavorite', 'getEnrolledCoursesAction']),

        ...mapMutations(['alertMutation']),

        changeColor() {
            if (this.data.course_card) {
                this.data.course_card.color = this.selectedColor
            }
            
            this.updateCourseColor({
                course_id: this.data.id,
                color: this.selectedColor
            })
        },

        errorMessage(){
            this.loading = false
            this.alertMutation({
                show: true,
                text: 'Something went wrong',
                type: "error"
            })
        },

        changeFavorite() {
            this.loading = true
            let newFavoriteStatus = !this.favorite;

            this.updateCourseFavorite({
                course_id: this.data.id,
                is_favorite: newFavoriteStatus
            }).then(() => {
                this.getEnrolledCoursesAction(this.pagination).then(() => {
                    this.loading = false
                    this.alertMutation({
                        show: true,
                        text: `${ newFavoriteStatus ? 'Successfully added to your favorites.' : 'Successfully removed from your favorites.' }`,
                        type: "success"
                    })
                }).catch(() => {
                    this.errorMessage()
                })
            }).catch(() => {
                this.errorMessage()
            })
        }
    },
    computed: {
        ...mapState({
            tenant: (state) => state.tenant,
        }),

        ...mapState('usr', {
            pagination: (state) => state.pagination,
        }),
        
        favorite(){
            return  this.data.course_favorite_has_users_count === 1 ? true : false
        }
    },
}
</script>