<template>
  <section>
    <v-card class="custom-border border pa-2">
      <section class="d-flex flex-row justify-space-between mx-3 mt-3">
        <div class="poppins fw600 f18 secondary-1--text">My Courses</div>
        <div class="d-flex justify-end" v-if="courses.length>0">
          <v-btn 
            text 
            small
            @click="gallery =  true"
            class="hidden-sm-and-down"
          >
            <v-icon size="22" :class="gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-view-gallery-outline
            </v-icon>
          </v-btn>
          <v-btn 
            text 
            small
            @click="gallery = false"
            class="hidden-sm-and-down"
          >
            <v-icon size="22" :class="!gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>
        </div>
      </section>
      <section v-if="loading" class="ma-4">
        <v-progress-linear
          indeterminate
          color="primary"
          rounded
          height="3px"
          ></v-progress-linear>
      </section>
      <v-sheet v-if="courses.length>0" class="d-flex overflow-y-auto my-4 mx-4 " :class="[gallery ? 'flex-wrap' : 'flex-column']">
        <CardGallery 
          v-for="(item,i) in courses"
          v-if="gallery"
          :key="i"
          :data="item.course"
          :progress="getProgress(item.course)"
          class="ma-1"
        />
        <CardList 
          v-for="(item,i) in courses"
          v-if="!gallery"
          :key="i"
          :data="item.course"
          :progress="getProgress(item.course)"
          class="my-1"
        />
      </v-sheet>
        
      <FormPagination 
        v-if="courses.length>0"
        :pageCount="pagination.pageCount" 
        :page="pagination.page"
        :paginate="String(pagination.paginate)"
        @page="(e) => { paginationMutation({ ...pagination, page: e}), getEnrolledCourses() }" 
        @paginate="(e) => { paginationMutation({ ...pagination, paginate: e, page: 1}), getEnrolledCourses() }"/>
      
      <!-- <div v-if="courses.length>0" :class="gallery && 'coursebox'">
        <CourseCard 
          v-for="item in courses" 
          :key="item.id" 
          :data="item" 
          :progress="getProgress(item)"
          :gallery="gallery"/>
      </div> -->
      <v-sheet
        v-if="courses.length === 0 && !loading"
        color="transparent"
        class="d-flex flex-column align-center justify-center pb-10"
        height="400"
      >
        <div class="">
          <v-img 
            :max-width="$vuetify.breakpoint.mobile?200:300" 
            :src="require('../../assets/default/empty_drafts.png')"/>
        </div>
        <h3 class="poppins secondary-1--text fw600 my-5">Nothing in Courses</h3>
      </v-sheet>

      <v-card-text v-if="tenant === 'corporate' && other_courses.length > 0">
        <div v-if="tenant === 'corporate' && other_courses.length > 0" class="poppins fw600 f18 secondary-1--text mb-3">Browse for more courses</div>
        <v-slide-group
            show-arrows
            class="mx-2"
        >
            <v-slide-item v-for="course in other_courses" :key="course.id">
                <GalleryCards class="ma-1" :course="course" />
            </v-slide-item>
        </v-slide-group>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import CardGallery from '../../components/user/course/CardGallery.vue';
import CardList from '../../components/user/course/CardList.vue';
import CourseCard from '../../components/user/course/Card.vue'
import GalleryCards from '../../components/user/course/GalleryCard.vue'

export default {
  components: {
    CourseCard,
    CardGallery,
    CardList,
    GalleryCards
  },
  data: () => ({
    loading: false,
    gallery: true,
  }),
  computed: {
    ...mapState({
      other_courses: (state) => state.other_courses,
      tenant: (state) => state.tenant,
    }),
    ...mapState('usr', {
      courses: (state) => state.courses,
      cart: (state) => state.cart,
      pagination: (state) => state.pagination,
    }),
  },

  mounted() {
    window.addEventListener('resize', this.onResize, {passive: true})
    this.onResize()
    this.getEnrolledCourses()
  },

  methods: {
    ...mapActions('usr', ['getEnrolledCoursesAction']),
    ...mapMutations('usr', ['paginationMutation']),

    getEnrolledCourses(){
      this.loading = true
      this.getEnrolledCoursesAction(this.pagination).finally(() => {
        this.loading = false
      })
    },

    getProgress(item){
      let progress = Math.round(((item.total_done_assessments + item.total_done_topics + item.total_done_sub_topics) / (item.assessments_count + item.topics_count + item.sub_topics_count)) * 100)
      return progress ? progress : 0
    },

    onResize(){
      if(this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.mobile){
        this.gallery = false
      }
    }
  },
}
</script>